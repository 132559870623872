const hamburgerToggle = () => {

    let hamburger = document.querySelector('.hamburger'); 
    let nav = document.querySelector('.nav-nav-mobile');
    const body = document.querySelector('body'); 
    let navContainer = document.querySelector('.nav-container-mobile')

    hamburger.onclick = () => {
        nav.classList.toggle('open');
        hamburger.classList.toggle('open');
        body.classList.toggle('locked');
        navContainer.classList.toggle('open');
        }

}; 

export default hamburgerToggle; 