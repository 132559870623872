window.$ = require('jquery');
window.jQuery = window.$;

const share = () => {
    var fbShare = function(url, title, descr, image, winWidth, winHeight)
    {
        var winTop = (screen.height / 2) - (winHeight / 2);
        var winLeft = (screen.width / 2) - (winWidth / 2);
        window.open('http://www.facebook.com/sharer.php?u=' + url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width=' + winWidth + ',height=' + winHeight);
    }

    $('.js-share-facebook').on('click', function(e) {
        e.preventDefault();

        var _this = $(this);
        var url = _this.attr('href');
        var title = _this.attr('data-title');
        var desc = _this.attr('data-desc');
        var image = _this.attr('data-image');

        fbShare(url, title, desc, image, 520, 350);
    });

    $('.js-share-twitter').click(function(e){
        //We tell our browser not to follow that link
        e.preventDefault();
        //We get the URL of the link
        var loc = $(this).attr('href');
        //We get the title of the link
        var title  = $(this).attr('data-title');
        //We trigger a new window with the Twitter dialog, in the middle of the page
        window.open('http://twitter.com/share?url=' + loc + '&text=' + title + '&', 'twitterwindow', 'height=450, width=550, top='+($(window).height()/2 - 225) +', left='+$(window).width()/2 +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
    });
}

export default share;