window.$ = require('jquery');
window.jQuery = window.$;

import flickity from 'flickity';

const topListsSelected = () => {


    // Slider containers
    const slider = document.querySelectorAll('.js-toplist-slider');

    // console.log(slider.length >= 1);

    if ( slider.length >= 1 ) {

        let groupCells = groupCells;
        let flkty = [];
        
        let storedFirstSlider;
        let storedSecondSlider;

        /**
         * @param first is first slider container
         * @param second is second slider container
         * @param firstRun {boolean} flag if initSlider runs for the first time 
         **/ 

        const initslider = (first, second, firstRun) => {
            
            let passedSliders = [first, second];
            
            if ( passedSliders != undefined ) {
                // console.log(passedSliders);
                for (let i = 0; i < passedSliders.length; i++) {
                        flkty[i] = new flickity(passedSliders[i], {
                        cellAlign: 'left',
                        pageDots: true,
                        prevNextButtons: true,
                        wrapAround: false,
                        contain: true,
                        selectedAttraction: 0.01,
                        friction: 0.15,
                        groupCells: false,
                    });
                }
                if(firstRun){
                    storedFirstSlider = flkty[0].slides.map(slide => slide.cells[0].element)
                    storedSecondSlider = flkty[1].slides.map(slide => slide.cells[0].element)
                }else{
                    flkty[0].slides.map(slide => flkty[0].remove(slide.cells[0].element))
                    flkty[1].slides.map(slide => flkty[1].remove(slide.cells[0].element))
                    // console.log('borde vara 0 -> ',flkty[0].slides.length)
                    storedFirstSlider.map(slide => flkty[0].append(slide));
                    storedSecondSlider.map(slide => flkty[1].append(slide));
                    flkty[0].resize();
                    flkty[0].select(0);
                    flkty[1].select(0);
                    // flkty[0].reloadCells()
                    // flkty[1].reloadCells()
                }

                
            
                
                removeUnusedSlides()
            }
        }

        initslider(slider[0], slider[1], true);

        $( "#select-toplists-articles" ).change(function() {
            var selectedEventType = this.options[this.selectedIndex].label;


                // console.log(selectedEventType);

                for (let i = 0; i < flkty.length; i++) {
                    flkty[i].destroy();
                };

                var link = this.value;
                $("#selected-link").attr("href", link);
                
                //need to pass slider containers, not the slide elements
                initslider(slider[0], slider[1], false);

        });
    
        function removeUnusedSlides(){
        
            const selectedValue = $('#select-toplists-articles').find(":selected")[0].label
            
            const firstRelevantSlides = storedFirstSlider.filter(slide => slide.dataset.value === selectedValue)
            const secondRelevantSlides = storedSecondSlider.filter(slide => slide.dataset.value === selectedValue)       

            let firstSliderRemoveThese =[]
            
            // console.log('slide 0 length: ',flkty[0].slides.length)
            for (let i = 0; i < flkty[0].slides.length; i++) {
                const slideElementDataValue = flkty[0].slides[i].cells[0].element.dataset.value;
                if(selectedValue !== slideElementDataValue){
                    firstSliderRemoveThese.push(flkty[0].slides[i].cells[0].element);

                }
            }

            let secondSliderRemoveThese = []
        
            for (let i = 0; i < flkty[1].slides.length; i++) {
                const slideElementDataValue = flkty[1].slides[i].cells[0].element.dataset.value;
                if(selectedValue !== slideElementDataValue){
                    secondSliderRemoveThese.push(flkty[1].slides[i].cells[0].element);
                }
            }


            if(firstSliderRemoveThese){
                // console.log( flkty[0].slides.length)
                // console.log('removing: ', firstSliderRemoveThese)
                flkty[0].remove(firstSliderRemoveThese);
            }

            if(secondSliderRemoveThese){
                flkty[1].remove(secondSliderRemoveThese);
            }
            
            
        }
    
    }

}

export default topListsSelected;
