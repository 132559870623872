import flickity from 'flickity';

const NewSliderHandlerRegular = () =>
{
    const newslider = document.querySelectorAll('.new-js-slider');

    let groupCells = groupCells;

    if ( newslider != undefined ) {
        for (let i = 0; i < newslider.length; i++) {
            let flkty = new flickity(newslider[i], {
                cellAlign: 'left',
                pageDots: true,
                prevNextButtons: true,
                wrapAround: false,
                contain: true,
                selectedAttraction: 0.01,
                friction: 0.15,
                groupCells: false,
            });

        }
    }
}

export default NewSliderHandlerRegular;