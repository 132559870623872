// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.

import SliderHandlerRegular from './articleSlider';
import toggleArticles from './toggleArticles';
import CompanySlider from './companySlider';
import share from './share';
import searchToggle from './searchToggle';
import menuList from './menuList';
import selectList from './selectList';
import hamburgerToggle from './hamburgerToggle';
import rocketsToggle from './rockets-toplist';
import topListsSelected from './toplists-selected-articles-slider'
import NewSliderHandlerRegular from './new-regular-slider'

document.addEventListener("DOMContentLoaded", function() {
    SliderHandlerRegular();
    toggleArticles();
    CompanySlider();
    share(); 
    searchToggle();
    menuList();
    selectList();
    hamburgerToggle();
    rocketsToggle();
    topListsSelected();
    NewSliderHandlerRegular();
});