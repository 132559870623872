window.$ = require('jquery');
window.jQuery = window.$;

const menuList = () => {

$(document).ready(function(){
    $('.menu-item-has-children > a').on("click", function(e){
      $(this).parent().toggleClass('open');
        e.stopPropagation();
        e.preventDefault();
    });
});

$(document).ready(function(){
    $('.toplists-menu').on("click", function(e){
      $(this).toggleClass('open');
    });
});
}

export default menuList;