window.$ = require('jquery');
window.jQuery = window.$;

const toggleArticles = () => {

    $('.toggle-articles').click(function(e) {
    e.preventDefault();
    $(this).next('.toggle-articles-container').slideToggle("linear");
    $(this).toggleClass('open');
    });
}

export default toggleArticles;