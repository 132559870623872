window.$ = require('jquery');
window.jQuery = window.$;

const selectList = () => {

$('#id_of_select').on('change', function() {
    var link = this.value;
    $("#change-link").attr("href", link);
});

};

export default selectList;