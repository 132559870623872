const searchToggle = () => {
    var searchBtn = document.querySelectorAll('#search-btn');
        Array.prototype.forEach.call(searchBtn, function(elements) {
            elements.onclick = () => {
                let searchContainer = document.querySelectorAll('#search');
                Array.prototype.forEach.call(searchContainer, function(elements) {
                    elements.classList.toggle('open');
                });
            }
    });
};

export default searchToggle;